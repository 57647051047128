<template>
    <div class="D6jlIndex" style="height:100%">
        <div class="D6jlIndex-container">
            <div class="d6-dot1 dotCo1">
                至放空总管
            </div>
            <div class="d6-btn d6-btn1">
                {{infoList.DB1V.DG122 ? '解列': '入列'}}
            </div>
            <div class="d6-btn d6-btn2">
                 {{infoList.DB1V.DG121 ? '解列': '入列'}}
            </div>
              <div class="d6-btn d6-btn3">
                 {{infoList.DB1V.DG120 ? '解列': '入列'}}
            </div>
            <div class="d6-btn d6-btn4">
                 {{infoList.DB1V.DG116 ? '解列': '入列'}}
            </div>
            <div class="d6-btn d6-btn5">
                {{infoList.DB1V.DG117 ? '解列': '入列'}}
            </div>
            <div class="d6-btn d6-btn-g d6-btn6">
                {{infoList.DB1S.YLBQH ? '磁力泵': '离心泵'}}
            </div>
                <div class="d6-btn d6-btn7">
                 {{infoList.DB1V.DG119 ? '解列': '入列'}}
            </div>
            <div class="d6-btn d6-btn-g d6-btn8">
                {{infoList.DB1S.CYBQH ? '磁力泵': '离心泵'}}
            </div>
                <div class="d6-btn d6-btn9">
               {{infoList.DB1V.DG198 ? '解列': '入列'}}
            </div>
          <div class="d6-con d6-con1">
            <div class="cell">V401</div>
            <div class="cell1">1#原料罐</div>
           </div>
            <div class="d6-con d6-con2">
            <div class="cell">V103</div>
            <div class="cell1">产品罐</div>
           </div>
            <div class="d6-con d6-con3">
            <div class="cell">V101</div>
            <div class="cell1">原料罐</div>
           </div>
           <div class="d6-dot2 dotCo2"
            @click="toCompon(2, 'TI110', 'DB1S', 'TI110','精馏塔顶温度')"
            @dblclick="Cclick(infoList.DB1S.TI110,'TI110', 'DB1S', 'TI110')"
           >
                {{infoList.DB1S.TI110}}℃
           </div>
           <div class="d6-dot3 dotCo2"
            @click="toCompon(2, 'TI109', 'DB1S', 'TI109','冷凝器出水温度')"
            @dblclick="Cclick(infoList.DB1S.TI109,'TI109', 'DB1S', 'TI109')"
           >
                {{infoList.DB1S.TI109}}℃
           </div>
            <div class="d6-dot4 dotCo1">
                至放空总管
            </div>
            <div class="d6-dot5 dotCo1">
                自储气罐
            </div>
            <div class="d6-tab">
                <div class="d6-tab-cell flex">
                    <div class="cell1">
                        温度
                    </div>
                    <div class="cell2"
                      @click="toCompon(2, 'FI401', 'DB1S', 'FI401','反应釜1#罐温度')"
                      @dblclick="Cclick(infoList.DB1S.FI401,'FI401', 'DB1S', 'FI401')"
                    >
                        {{infoList.DB1S.FI401}}℃
                    </div>
                </div>
                <div class="d6-tab-cell flex">
                    <div class="cell1">
                        液位
                    </div>
                    <div class="cell2"
                     @click="toCompon(2, 'LI401', 'DB1S', 'LI401','1#原料罐液位')"
                      @dblclick="Cclick(infoList.DB1S.LI401,'LI401', 'DB1S', 'LI401')"
                    >
                        {{infoList.DB1S.LI401}} %
                    </div>
                </div>
                <div class="d6-tab-cell flex">
                    <div class="cell1">
                        体积
                    </div>
                    <div class="cell2 cell1"
                        @click="toCompon(2, 'LI401', 'FYFTJ', 'LI40201','2#原料罐液位计算值')"
                      @dblclick="Cclick(infoList.FYFTJ.LI401,'LI401', 'FYFTJ', 'LI40201')"
                    >
                        {{infoList.FYFTJ.BL0003}}
                    </div>
                </div>
                <div class="d6-tab-cell flex">
                    <div class="cell1">
                        流量
                    </div>
                    <div class="cell2 cell1"
                      @click="toCompon(2, 'AN38', 'DB1V', 'AN38_JLT','反应釜1#罐下液流量软测量')"
                      @dblclick="Cclick(infoList.DB1V.AN38,'AN38', 'DB1V', 'AN38_JLT')"
                    >
                       {{infoList.DB1V.AN38}}
                    </div>
                </div>
            </div>
            <div class="d6-tab1">
                <div class="cell">
                    软测量
                </div>
                <div class="cell"
                 @click="toCompon(2, 'AN333', 'DB1V', 'AN333_JLT','备用')"
                @dblclick="Cclick(infoList.DB1V.AN333,'AN333', 'DB1V', 'AN333_JLT')"
                >
                    {{infoList.DB1V.AN333}}  L/h
                </div>
            </div>
                <div class="d6-tab2">
                <div class="cell">
                    温度
                     <span
                      @click="toCompon(2, 'TI001', 'DB1S', 'TI001','公用水箱温度')"
                      @dblclick="Cclick(infoList.DB1S.TI001,'TI001', 'DB1S', 'TI001')"
                     >
                    {{infoList.DB1S.TI001}}℃ 
                        </span>
                </div>
                <div class="cell">
                    压力
                     <span
                      @click="toCompon(2, 'PI302', 'DB1S', 'PI302','水泵出口压力')"
                      @dblclick="Cclick(infoList.DB1S.PI302,'PI302', 'DB1S', 'PI302')"
                     >
                        {{infoList.DB1S.PI302}} Kpa
                     </span>
                </div>
            </div>
             <div class="d6-dot6 dotCo3">
                压差 <span
                @click="toCompon(2, 'AN173', 'DB1V', 'AN173_JLT','精馏单元精馏塔顶压差')"
                @dblclick="Cclick(infoList.DB1V.AN173,'AN73', 'DB1V', 'AN173_JLT')"
                >
                    {{infoList.DB1V.AN173}}
                </span>
           </div>
           <div class="d6-dot7 dotCo2"
             @click="toCompon(2, 'PI103', 'DB1S', 'PI103','塔顶压力')"
                      @dblclick="Cclick(infoList.DB1S.PI103,'PI103', 'DB1S', 'PI103')"
           >
                {{infoList.DB1S.PI103}} Kpa
           </div>
           <div class="d6-dot8 dotCo2"
            @click="toCompon(2, 'TI106', 'DB1S', 'TI106','塔顶回流温度')"
            @dblclick="Cclick(infoList.DB1S.TI106,'TI106', 'DB1S', 'TI106')"
           >
                {{infoList.DB1S.TI106}}℃ 
           </div>
           <div class="d6-dot9 dotCo1">
                <div class="dot9-1">E102</div>
                 <div class="dot9-2">冷凝器</div>
           </div>
            <div class="d6-dot10 dotCo2"
              @click="toCompon(2, 'FI105', 'DB1S', 'FI105','冷凝器冷却温度')"
                @dblclick="Cclick(infoList.DB1S.FI105,'FI105', 'DB1S', 'FI105')"
            >
                  {{infoList.DB1S.FI105}}  L/h
           </div>
           <div class="d6-dot11 dotCo1">
                自供水罐
           </div>
            <div class="d6-dot12 dotCo1">
                去间歇反应单元
           </div>
             <div class="d6-dot13 dotCo2"
              @click="toCompon(2, 'FV401_MV', 'DB1S', 'FV401_MV','1#原料罐出口料液电动调节阀跟踪')"
            @dblclick="Cclick(infoList.DB1S.FV401_MV,'FV401_MV', 'DB1S', 'FV401_MV')"
             >
               {{infoList.DB1S.FV401_MV}}%
           </div>
           <div class="d6-dot14 dotCo2"
            @click="toCompon(2, 'TI101', 'DB1S', 'TI101','原料液进口温度')"
            @dblclick="Cclick(infoList.DB1S.TI101,'TI101', 'DB1S', 'TI101')"
           >
               {{infoList.DB1S.TI101}}℃
           </div>
            <div class="d6-dot15 dotCo2">
               第三层
           </div>
            <div class="d6-dot16 dotCo2">
               第六层
           </div>
            <div class="d6-dot17 dotCo2">
               第九层
           </div>
            <div class="d6-dot18 dotCo2">
               第十二层
           </div>
            <div class="d6-dot19 dotCo2">
               塔釜气相
           </div>
             <div class="d6-dot20 dotCo2">
               26.03℃
           </div>
            <div class="d6-dot20 dotCo2"
             @click="toCompon(2, 'TI105', 'DB1S', 'TI105','2#塔板上温度')"
            @dblclick="Cclick(infoList.DB1S.TI105,'TI105', 'DB1S', 'TI105')"
            >
              {{infoList.DB1S.TI105}}℃
           </div>
             <div class="d6-dot21 dotCo3"
              @click="toCompon(2, 'AN179', 'DB1V', 'AN179_JLT','精馏单元提溜段温差')"
             @dblclick="Cclick(infoList.DB1V.AN179,'AN179', 'DB1V', 'AN179_JLT')"
             >
                {{infoList.DB1V.AN179}} <span>温差</span>
           </div>
            <div class="d6-dot22 dotCo2"
            @click="toCompon(2, 'TI104', 'DB1S', 'TI104','7#塔板上温度')"
            @dblclick="Cclick(infoList.DB1S.TI104,'TI104', 'DB1S', 'TI104')"
            >
               {{infoList.DB1S.TI104}}℃
           </div>
           <div class="d6-dot23 dotCo2"
           @click="toCompon(2, 'TI111', 'DB1S', 'TI111','精馏塔10块塔板上温度')"
            @dblclick="Cclick(infoList.DB1S.TI111,'TI111', 'DB1S', 'TI111')"
           >
               {{infoList.DB1S.TI111}}℃
           </div>
             <div class="d6-dot24 dotCo3"
             @click="toCompon(2, 'AN178', 'DB1V', 'AN178_JLT','精馏单元精溜段温差')"
             @dblclick="Cclick(infoList.DB1V.AN178,'AN178', 'DB1V', 'AN178_JLT')"
             >
                {{infoList.DB1V.AN178}} <span>温差</span>
           </div>
            <div class="d6-dot25 dotCo2"
             @click="toCompon(2, 'TI103', 'DB1S', 'TI103','第13#塔板气相区温度')"
            @dblclick="Cclick(infoList.DB1S.TI103,'TI103', 'DB1S', 'TI103')"
            >
               {{infoList.DB1S.TI103}}℃
           </div>
           <div class="d6-dot26 dotCo2"
           @click="toCompon(2, 'PI102', 'DB1S', 'PI102','塔底压力')"
            @dblclick="Cclick(infoList.DB1S.PI102,'PI102', 'DB1S', 'PI102')"
           >
               {{infoList.DB1S.PI102}} Kpa
           </div>
             <div class="d6-dot27 dotCo3"
              @click="toCompon(2, 'AN199', 'DB1V', 'AN199_JLT','精馏塔塔釜体积软测量')"
             @dblclick="Cclick(infoList.DB1V.AN199,'AN199', 'DB1V', 'AN199_JLT')"
             >
                {{infoList.DB1V.AN199}} L
           </div>
            <div class="d6-dot28 dotCo2"
            @click="toCompon(2, 'LI104', 'DB1S', 'LI104','塔釜液位')"
            @dblclick="Cclick(infoList.DB1S.LI104,'LI104', 'DB1S', 'LI104')"
            >
               {{infoList.DB1S.LI104}}%
           </div>
            <div class="d6-dot29 dotCo3">
                流量软测量
           </div>
            <div class="d6-dot30 dotCo3"
            @click="toCompon(2, 'AN180', 'DB1V', 'AN180_JLT','精馏单元回流流量软测量')"
             @dblclick="Cclick(infoList.DB1V.AN180,'AN180', 'DB1V', 'AN180_JLT')"
            >
                {{infoList.DB1V.AN180}}
           </div>
            <div class="d6-dot31 dotCo2"
            @click="toCompon(2, 'FI102', 'DB1S', 'FI102','塔顶回流液流量')"
            @dblclick="Cclick(infoList.DB1S.FI102,'FI102', 'DB1S', 'FI102')"
            >
               {{infoList.DB1S.FI102}} L
           </div>
           <div class="d6-dot32 dotCo1">
                P102
           </div>
           <div class="d6-dot33 dotCo2">
                回流泵
           </div>
            <div class="d6-dot34 dotCo2"
             @click="toCompon(2, 'TI107', 'DB1S', 'TI107','冷凝液出口温度')"
            @dblclick="Cclick(infoList.DB1S.TI107,'TI107', 'DB1S', 'TI107')"
            >
                {{infoList.DB1S.TI107}}℃
           </div>
            <div class="d6-dot35 dotCo1">
                V102
           </div>
           <div class="d6-dot36 dotCo2">
                回流罐
           </div>
            <div class="d6-dot37 dotCo3">
                浓度软测量
           </div>
            <div class="d6-dot38 dotCo3"
            @click="toCompon(2, 'AN203', 'DB1V', 'AN203_JLT','精馏塔顶回流罐浓度软测量')"
             @dblclick="Cclick(infoList.DB1V.AN203,'AN203', 'DB1V', 'AN203_JLT')"
            >
               {{infoList.DB1V.AN203}}
           </div>
            <div class="d6-dot39 dotCo3">
                浓度分析
           </div>
            <div class="d6-dot40 dotCo3"
            @click="toCompon(2, 'AN120', 'DB1V', 'AN120_JLT','精馏塔顶回流罐浓度分析值')"
             @dblclick="Cclick(infoList.DB1V.AN120,'AN120', 'DB1V', 'AN120_JLT')"
            >
                 {{infoList.DB1V.AN120}}
           </div>
             <div class="d6-dot41 dotCo2">
                冷凝液取样口
           </div>
           <div class="d6-dot42 dotCo2"
            @click="toCompon(2, 'BP102_MV', 'DB1S', 'BP102_MV','精馏塔回流泵变频DCS优化输出')"
            @dblclick="Cclick(infoList.DB1S.BP102_MV,'BP102_MV', 'DB1S', 'BP102_MV')"
           >
                {{infoList.DB1V.BP102_MV}}%
           </div>
           <div class="d6-dot43 dotCo2">
                加料口
           </div>
           <div class="d6-dot44 dotCo3"
           @click="toCompon(2, 'AN98', 'DB1V', 'AN98_JLT','精馏回流罐体积软测量')"
             @dblclick="Cclick(infoList.DB1V.AN98,'AN98', 'DB1V', 'AN98_JLT')"
           >
                 {{infoList.DB1V.AN98}} L
           </div>
           <div class="d6-dot45 dotCo2"
             @click="toCompon(2, 'LI102', 'DB1S', 'LI102','回流罐液位')"
            @dblclick="Cclick(infoList.DB1S.LI102,'LI102', 'DB1S', 'LI102')"
           >
                 {{infoList.DB1S.LI102}}%
           </div>
           <div class="d6-dot46 dotCo2"
           @click="toCompon(2, 'BP104_MV', 'DB1S', 'BP104_MV','精馏塔塔顶产品采出泵变频DCS优化输出')"
            @dblclick="Cclick(infoList.DB1S.BP104_MV,'BP104_MV', 'DB1S', 'BP104_MV')"
           >
                 {{infoList.DB1S.BP104_MV}}%
           </div>
           <div class="d6-dot47 dotCo2"
             @click="toCompon(2, 'FV105_MV', 'DB1S', 'FV105_MV','冷凝水阀DCS优化输出')"
            @dblclick="Cclick(infoList.DB1S.FV105_MV,'FV105_MV', 'DB1S', 'FV105_MV')"
           >
                 {{infoList.DB1S.FV105_MV}}%
           </div>
           <div class="d6-dot48 dotCo2"
            @click="toCompon(2, 'PV106_AI', 'DB1S', 'PV106_AI','精馏中间罐压力调节阀反馈')"
            @dblclick="Cclick(infoList.DB1S.PV106_AI,'PV106_AI', 'DB1S', 'PV106_AI')"
           >
                 {{infoList.DB1S.PV106_AI}}%
           </div>
            <div class="d6-dot49 dotCo2">
                 至放空管
           </div>
            <div class="d6-dot50 dotCo3">
                流量软测量
           </div>
            <div class="d6-dot51 dotCo3"
              @click="toCompon(2, 'AN181', 'DB1V', 'AN181_JLT','精馏单元采出流量软测量')"
             @dblclick="Cclick(infoList.DB1V.AN181,'AN181', 'DB1V', 'AN181_JLT')"
            >
               {{infoList.DB1V.AN181}}
           </div>
              <div class="d6-dot52 dotCo2"
              @click="toCompon(2, 'FI103', 'DB1S', 'FI103','塔顶产品流量')"
            @dblclick="Cclick(infoList.DB1S.FI103,'FI103', 'DB1S', 'FI103')"
              >
                  {{infoList.DB1S.FI103}} L/h
           </div>
           <div class="d6-dot53 dotCo1">
                P102
           </div>
           <div class="d6-dot54 dotCo2">
                回流泵
           </div>
             <div class="d6-dot55 dotCo2">
                 至放空管
           </div>
            <div class="d6-dot56 dotCo2">
                 至废空管
           </div>
            <div class="d6-dot57 dotCo3"
              @click="toCompon(2, 'AN90', 'DB1V', 'AN90_JLT','精馏产品罐体积软测量值单位L')"
             @dblclick="Cclick(infoList.DB1V.AN90,'AN90', 'DB1V', 'AN90_JLT')"
            >
                {{infoList.DB1V.AN90}} L
           </div>
           <div class="d6-dot58 dotCo2"
            @click="toCompon(2, 'LI103', 'DB1S', 'LI103','产品罐液位')"
            @dblclick="Cclick(infoList.DB1S.LI103,'LI103', 'DB1S', 'LI103')"
           >
                 {{infoList.DB1S.LI103}} %
           </div>
            <div class="d6-dot59 dotCo2">
                 40L
           </div>
           <div class="d6-dot60 dotCo3"
             @click="toCompon(2, 'AN121', 'DB1V', 'AN121_JLT','精馏产品罐浓度分析值')"
             @dblclick="Cclick(infoList.DB1V.AN121,'AN121', 'DB1V', 'AN121_JLT')"
           >
              {{infoList.DB1V.AN121}} <span>浓度分析</span>
           </div>
               <div class="d6-dot61 dotCo2">
                 至接料桶
           </div>
            <div class="d6-dot62 dotCo2">
                 至放空管
           </div>
            <div class="d6-dot63 dotCo2">
                加料口
           </div>
           <div class="d6-dot64 dotCo3">
                流量软测量
           </div>
           <div class="d6-dot65 dotCo3">
                <!-- 0.00 -->
           </div>
           <div class="d6-dot66 dotCo2"
           @click="toCompon(2, 'FI101', 'DB1S', 'FI101','原料进料流量')"
            @dblclick="Cclick(infoList.DB1S.FI101,'FI101', 'DB1S', 'FI101')"
           >
                {{infoList.DB1S.FI101}} L/h
           </div>
            <div class="d6-dot67 dotCo3"
             @click="toCompon(2, 'AN92', 'DB1V', 'AN92_JLT','精馏原料罐体积软测量值单位L')"
             @dblclick="Cclick(infoList.DB1V.AN92,'AN92', 'DB1V', 'AN92_JLT')"
            >
                {{infoList.DB1V.AN92}} L
           </div>
           <div class="d6-dot68 dotCo2"
            @click="toCompon(2, 'LI101', 'DB1S', 'LI101','原料罐液位')"
            @dblclick="Cclick(infoList.DB1S.LI101,'LI101', 'DB1S', 'LI101')"
           >
                {{infoList.DB1S.LI101}}%
           </div>
           <div class="d6-dot69 dotCo2"
           @click="toCompon(2, 'PI101', 'DB1S', 'PI101','进料泵出口压力')"
            @dblclick="Cclick(infoList.DB1S.PI101,'PI101', 'DB1S', 'PI101')"
           >
                {{infoList.DB1S.PI101}} L/h
           </div>
            <div class="d6-dot70 dotCo3">
                浓度分析
           </div>
            <div class="d6-dot71 dotCo3"
             @click="toCompon(2, 'AN118', 'DB1V', 'AN118_JLT','精馏原料罐浓度分析值')"
             @dblclick="Cclick(infoList.DB1V.AN118,'AN118', 'DB1V', 'AN118_JLT')"
            >
                {{infoList.DB1V.AN118}}
           </div>
           <div class="d6-dot72 dotCo2">
                 原料罐排污口
           </div>
           <div class="d6-dot73 dotCo2"
            @click="toCompon(2, 'TI108', 'DB1S', 'TI108','1#再沸器液体温度')"
            @dblclick="Cclick(infoList.DB1S.TI108,'TI108', 'DB1S', 'TI108')"
           >
               {{infoList.DB1S.TI108}}℃
           </div>
           <div class="d6-dot74 dotCo2"
           @click="toCompon(2, 'E101A_MV', 'DB1S', 'E101A_MV','再沸器电加热DCS优化输出')"
            @dblclick="Cclick(infoList.DB1S.E101A_MV,'E101A_MV', 'DB1S', 'E101A_MV')"
           >
               {{infoList.DB1S.E101A_MV}}%
           </div>
           <div class="d6-dot75 dotCo2">
                 进料取样口
           </div>
             <div class="d6-dot76 dotCo1">
                P101
           </div>
           <div class="d6-dot77 dotCo2">
                进流泵
           </div>
           <div class="d6-dot78 dotCo2"
             @click="toCompon(2, 'BP101_MV', 'DB1S', 'BP101_MV','进料泵变频DCS优化输出')"
            @dblclick="Cclick(infoList.DB1S.BP101_MV,'BP101_MV', 'DB1S', 'BP101_MV')"
           >
               {{infoList.DB1S.BP101_MV}}%
           </div>
              <div class="d6-dot79 dotCo2">
                 进料取样口
           </div>
              <div class="d6-dot80 dotCo1">
                E101A
           </div>
           <div class="d6-dot81 dotCo2">
                A再沸腾
           </div>
              <div class="d6-dot82 dotCo1">
                T101
           </div>
           <div class="d6-dot83 dotCo2">
            精馏塔
           </div>
            <div class="d6-dot84 dotCo2">
               60 L
           </div>
            <div class="d6-dot85 dotCo3">
                浓度分析
           </div>
            <div class="d6-dot86 dotCo3"
              @click="toCompon(2, 'AN119', 'DB1V', 'AN119_JLT','精馏塔釜浓度分析值')"
             @dblclick="Cclick(infoList.DB1V.AN119,'AN119', 'DB1V', 'AN119_JLT')"
            >
                 {{infoList.DB1V.AN119}}
           </div>
            <div class="d6-dot87 dotCo2"
             @click="toCompon(2, 'TI102', 'DB1S', 'TI102','塔釜温度')"
            @dblclick="Cclick(infoList.DB1S.TI102,'TI102', 'DB1S', 'TI102')"
            >
                {{infoList.DB1S.TI102}}℃
           </div>
              <div class="d6-dot88 dotCo3">
                底部温差
                 <span
                  @click="toCompon(2, 'AN122', 'DB1V', 'AN122_JLT','精馏塔底温差')"
             @dblclick="Cclick(infoList.DB1V.AN122,'AN122', 'DB1V', 'AN122_JLT')"
                 >
                     {{infoList.DB1V.AN122}}
                </span>
           </div>
            <div class="d6-dot89 dotCo3"
               @click="toCompon(2, 'AN206', 'DB1V', 'AN206_JLT','精馏塔底浓度软测量')"
             @dblclick="Cclick(infoList.DB1V.AN206,'AN206', 'DB1V', 'AN206_JLT')"
            >
                浓度软测量 {{infoList.DB1V.AN206}}
           </div>
            <div class="d6-dot90 dotCo2">
                至放空管
           </div>
               <div class="d6-dot91 dotCo1">
                E101B
           </div>
           <div class="d6-dot92 dotCo2">
                B再沸腾
           </div>
            <div class="d6-dot93 dotCo2">
                管道残液排出口
           </div>
              <div class="d6-dot94 dotCo2"
               @click="toCompon(2, 'BP103_MV', 'DB1S', 'BP103_MV','残液泵变频DCS优化输出')"
            @dblclick="Cclick(infoList.DB1S.BP103_MV,'BP103_MV', 'DB1S', 'BP103_MV')"
              >
               {{infoList.DB1S.BP103_MV}}%
           </div>
            <div class="d6-dot95 dotCo2"
             @click="toCompon(2, 'PI104', 'DB1S', 'PI104','2#再沸器进口蒸汽压力')"
            @dblclick="Cclick(infoList.DB1S.PI104,'PI104', 'DB1S', 'PI104')"
            >
                {{infoList.DB1S.PI104}}Kpa
           </div>
            <div class="d6-dot96 dotCo2"
             @click="toCompon(2, 'PI105', 'DB1S', 'PI105','残液泵出口压力')"
            @dblclick="Cclick(infoList.DB1S.PI105,'PI105', 'DB1S', 'PI105')"
            >
                {{infoList.DB1S.PI105}}Kpa
           </div>
            <div class="d6-dot97 dotCo1">
                P103
           </div>
           <div class="d6-dot98 dotCo2">
                    残液泵
           </div>
           <div class="d6-dot99 dotCo2">
                    残液取样口
           </div>
             <div class="d6-dot100 dotCo3">
                <!-- 流量软测量 -->
           </div>
            <div class="d6-dot101 dotCo3">
                <!-- 3.00 -->
           </div>
            <div class="d6-dot102 dotCo2"
                @click="toCompon(2, 'FI104', 'DB1S', 'FI104','塔底产品流量')"
            @dblclick="Cclick(infoList.DB1S.FI104,'FI104', 'DB1S', 'FI104')"
            >
                 {{infoList.DB1S.FI104}} L/H
           </div>
           <div class="d6-dot103 dotCo2">
                蒸汽压力温度
           </div>
           <div class="d6-dot104 dotCo2"
            @click="toCompon(2, 'TI314', 'DB1S', 'TI314','分汽包蒸汽出口温度')"
            @dblclick="Cclick(infoList.DB1S.TI314,'TI314', 'DB1S', 'TI314')"
           >
                {{infoList.DB1S.TI314}}℃
           </div>
           <div class="d6-dot105 dotCo2"
             @click="toCompon(2, 'PI305', 'DB1S', 'PI305','分汽包压力')"
            @dblclick="Cclick(infoList.DB1S.PI305,'PI305', 'DB1S', 'PI305')"
           >
                {{infoList.DB1S.PI305}}Kpa
           </div>
             <div class="d6-dot106 dotCo2">
              自蒸汽调节阀
           </div>
             <div class="d6-dot107 dotCo2"
               @click="toCompon(2, 'FV302_MV', 'DB1S', 'FV302_MV','蒸汽压力调节阀DCS优化输出')"
            @dblclick="Cclick(infoList.DB1S.FV302_MV,'FV302_MV', 'DB1S', 'FV302_MV')"
             >
                {{infoList.DB1S.FV302_MV}}%
           </div>
            <div class="d6-dot108 dotCo2">
                至废水罐
           </div>
            <div class="d6-dot109 dotCo2">
                至储水罐
           </div>
        </div>
           <Historical
      v-if="isHshow"
      @sendStatus="isClose"
      :historyname="historyname"
      :node="nodename"
      :Lkname="Aname"
	  :chName="chName" 
      :infoList="infoList"
    ></Historical>
    </div>
</template>
<script>
import index from "./_index/index.js";
import Historical from "@/components/Historical.vue"; //历史趋势
import Manual from "@/components/Manual.vue"; //手操器组件
export default {
    name:'D6jlIndex',
    components: {
        Historical,
        Manual
    },
      props:{
        infoList:{
            type:Object,
            default: () =>{
              return {}// 默认值
            } 
        }
    },
    data() {
        return {
             fullscreenLoading:false,
            maskbok:false,
            scaleHeight:'',
            spotArr:[],
            flag: 0,
            projectData:[],
             isHshow:false,
            historyname:'',
            nodename:'',
            Aname:'',
            chName:'',
        }
    },
    mixins: [index],
       watch: {
    infoList: {
      handler(n, o) {
        this.infoList = n;
      },
      deep: true, // 深度监听父组件传过来对象变化
    },
  },
    created() {
        this.projectData = JSON.parse(localStorage.getItem("deviceType"));
        console.log(this.infoList)
            this.$bus.$on("sendMsg", (msg,msg1) => {
      // 兄弟组件发送来的消息
      this.flag = msg;
           switch(this.flag){
        case 0:
		  return this.JRLcysz = true,this.$router.push({ path: '/6DjlIndex' })
        case 1:  
           return this.$router.push({ path: '/6DfyfIndex' })
        case 2:  
           return this.$router.push({ path: '/6Dqhhm' })
        case 3:  
           return this.$router.push({ path: '/6Dpub' })
          
       }
    });

    },
}
</script>
<style lang="scss" scoped>
@import "./_index/index.scss";
</style>